var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.isModePublic)?_c('v-app-bar',[_c('a',{attrs:{"href":"http://www.charterup.com","target":"_blank"}},[_c('CharterUPLogo',{staticClass:"charterup-logo",class:_vm.$vuetify.breakpoint.xs ? 'mobile' : ''})],1),(_vm.loading)?_c('SkeletonBox',{staticClass:"margin-l-4",style:({ width: '115px' }),attrs:{"type":"text"}}):_c('h3',{staticClass:"font-14 margin-l-4",style:({ 'margin-top': '2px' })},[_vm._v(" "+_vm._s(_vm.contractName)+" ")])],1):_vm._e(),_c('div',{staticClass:"flex-container",class:{ public: _vm.isModePublic },style:({ display: 'table !important' })},[_c('div',{staticClass:"alert-container",class:{
        public: _vm.isModePublic,
        mobile: _vm.$vuetify.breakpoint.xs,
      }},_vm._l((_vm.alerts),function(alert,alertIndex){return _c('AlertCard',{key:`${alertIndex}-${alert.externalId}`,staticClass:"recenter-button margin-b-2",attrs:{"alert":alert}})}),1),_c('div',{staticClass:"padding-x-0",class:{
        'table-view': _vm.isTableView,
        'padding-y-4': _vm.$vuetify.breakpoint.smAndUp,
        'padding-y-2': _vm.$vuetify.breakpoint.xsOnly,
      },attrs:{"id":"panel"}},[(!_vm.noTracking)?_c('EnterpriseTrackingSidebar',{attrs:{"last-vehicle-positions":_vm.lastVehiclePositions,"list-mode":_vm.listMode,"is-mode-public":_vm.isModePublic,"filters":_vm.filters,"is-enterprise-admin":_vm.isEnterpriseAdmin,"is-table-view":_vm.isTableView,"filtered-vehicles":_vm.filteredVehicles,"hover-item":_vm.hoverItem,"reservation-itineraries":_vm.reservationItineraries,"loading":_vm.loading,"eld-types":_vm.eldTypes},on:{"update:filters":function($event){_vm.filters=$event},"change-list-mode":function($event){_vm.listMode = $event},"hover":function($event){_vm.hoverItem = $event}}}):_vm._e()],1),_c('div',{attrs:{"id":"map"}},[_c('CountdownTimer',{style:(_vm.$vuetify.breakpoint.xs ? 'margin-right: -14px' : ''),attrs:{"hidden":true,"time-limit":_vm.refreshInterval,"size":_vm.timerSize},on:{"countdown-finished":_vm.refresh}}),_c('EnterpriseTrackingMap',{ref:"map",class:{ 'table-view': _vm.isTableView },attrs:{"id":"map","filtered-vehicles":_vm.filteredVehicles,"is-enterprise-admin":_vm.isEnterpriseAdmin,"is-mode-public":_vm.isModePublic,"is-table-view":_vm.isTableView,"reservation-itineraries":_vm.reservationItineraries,"hover-item":_vm.hoverItem,"filters":_vm.filters,"eld-types":_vm.eldTypes}})],1)]),_c('EnterpriseTrackingNoTrackingDialog',{attrs:{"next-reservation-time":_vm.nextReservationTime,"next-stop-timezone":_vm.nextStopTimezone},model:{value:(_vm.noTracking),callback:function ($$v) {_vm.noTracking=$$v},expression:"noTracking"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }