
import { DateTime } from 'luxon'
import CountdownTimer from '@/components/CountdownTimer.vue'
import AlertCard from '@/components/AlertCard.vue'
import EnterpriseTrackingMap from '@/components/EnterpriseTrackingMap.vue'
import EnterpriseTrackingNoTrackingDialog from '@/components/EnterpriseTrackingNoTrackingDialog.vue'
import EnterpriseTrackingSidebar from '@/components/EnterpriseTrackingSidebar.vue'
import CharterUPLogo from '@/icons/CharterUPLogo.vue'
import tracking from '@/services/tracking'
import alertService from '@/services/alert'
import auth from '@/store/modules/auth'
import { checkIfValidSHA256 } from '@/utils/string'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import eldDevice from '../services/eldDevice'
import { TypeWithId } from '../models/dto'
import SkeletonBox from '@/components/SkeletonBox.vue'

const PUBLIC_MODE = 'hash'
const CUSTOMER_ACCOUNT_MODE = 'customer-account'

@Component({
  components: {
    CharterUPLogo,
    CountdownTimer,
    EnterpriseTrackingMap,
    EnterpriseTrackingNoTrackingDialog,
    EnterpriseTrackingSidebar,
    AlertCard,
    SkeletonBox,
  },
})
export default class EnterpriseTracking extends Vue {
  @Prop({ type: String, required: true }) readonly mode: string

  @Watch('$vuetify.breakpoint.xsOnly')
  vuetifyBreakpointChanged(): void {
    if (this.$vuetify.breakpoint.xsOnly && this.isTableView) {
      this.listMode = 'vehicle'
    }
  }
  @Watch('filters')
  filtersChanged(): void {
    this.filteredVehicles = this.filterVehicles()
  }

  colors = [
    this.$vuetify.theme.themes.light.orange,
    this.$vuetify.theme.themes.light.green,
    this.$vuetify.theme.themes.light.blue,
    this.$vuetify.theme.themes.light.red,
    this.$vuetify.theme.themes.light.purple,
    this.$vuetify.theme.themes.light.yellow,
  ]
  contractName = null
  date = DateTime.local()
  filteredVehicles = []
  filters = {}
  hoverItem = '0'
  lastVehiclePositions = []
  listMode = 'vehicle'
  loading = true
  nextReservationTime = null
  nextStopTimezone = null
  noTracking = false
  reservationInfo = []
  refreshInterval = 15
  eldTypes: TypeWithId[] = []
  alerts = {}

  get timerSize(): number {
    if (this.$vuetify.breakpoint.smAndUp) {
      return 40
    }
    return 30
  }
  get isEnterpriseAdmin(): boolean {
    return auth.isEnterpriseAdmin && !this.isModePublic
  }
  get reservationItineraries(): any[] {
    const reservationItineraries = []
    for (const reservation of this.reservationInfo) {
      const isReservationInFilteredVehicles = this.filteredVehicles.some(
        (vehicle) => vehicle.reservationId === reservation.managedId
      )
      const isParentReservationAlreadyInItineraries = reservationItineraries.some(
        (resItinerary) =>
          resItinerary[0].reservationId === reservation.reservationId
      )
      if (
        isReservationInFilteredVehicles &&
        !isParentReservationAlreadyInItineraries &&
        reservation.stops
      ) {
        const itinerary = reservation.stops
          .filter(
            (stop, index, self) =>
              index ===
              self.findIndex(
                (s) =>
                  s.address.lat === stop.address.lat &&
                  s.address.lng === stop.address.lng
              )
          )
          .map((stop) => {
            return {
              address: stop.address,
              pickupTimes: new Set(),
              dropoffTimes: new Set(),
              color: reservation.color,
              reservationId: reservation.reservationId,
              tripName: reservation.tripName,
              managedId: reservation.managedId,
            }
          })
        for (const stop of reservation.stops) {
          const itineraryItem = itinerary.find(
            (waypoint) =>
              waypoint.address.lat === stop.address.lat &&
              waypoint.address.lng === stop.address.lng
          )
          if (stop.pickupDatetime) {
            itineraryItem.pickupTimes.add(stop.pickupDatetime)
          }
          if (stop.dropoffDatetime) {
            itineraryItem.dropoffTimes.add(stop.dropoffDatetime)
          }
        }
        for (const stop of itinerary) {
          stop.pickupTimes = [...stop.pickupTimes]
          stop.dropoffTimes = [...stop.dropoffTimes]
        }
        reservationItineraries.push(itinerary)
      }
    }
    return reservationItineraries
  }
  get isModePublic(): boolean {
    return this.mode === PUBLIC_MODE
  }
  get isModeCustomerAccount(): boolean {
    return this.mode === CUSTOMER_ACCOUNT_MODE
  }
  get isTableView(): boolean {
    return this.listMode === 'table-view'
  }

  async mounted(): Promise<void> {
    this.setDefaultListMode()
    this.date = DateTime.local()
    await this.getTrackingForAllVehicles(true)
    await this.getEldTypes()
    this.$nextTick(() => window.dispatchEvent(new Event('resize')))
  }
  created(): void {
    window.addEventListener('resize', this.matchHeights)
  }
  destroyed(): void {
    window.removeEventListener('resize', this.matchHeights)
  }
  async getEldTypes(): Promise<void> {
    const eldResponse = await eldDevice.types({ pageSize: -1 })
    const eldTypes = eldResponse.data.resultList
    this.eldTypes = eldTypes
  }
  async getTrackingForAllVehicles(firstLoad = false): Promise<void> {
    this.loading = firstLoad
    const filteredVehicles = firstLoad ? [] : this.filterVehicles()
    let response
    let vehicles = []
    const reservationInfo = []

    if (this.isModePublic) {
      response = await tracking.contractDataByHash(
        this.$route.params.hash,
        !checkIfValidSHA256(this.$route.params.hash)
      )
    } else if (this.isModeCustomerAccount) {
      response = await tracking.enterpriseMostRecentDataV2()
    }
    vehicles = response?.data?.gpsData || []
    if (vehicles.length) {
      let contractName = null
      for (const vehicle of vehicles) {
        vehicle.selected =
          this.isModePublic ||
          filteredVehicles.includes(vehicle.trak4DeviceId) ||
          firstLoad
        vehicle.lastTransmitted = vehicle.receivedDate
        vehicle.heading = this.computeHeading(vehicle)
        vehicle.hover = false
        vehicle.reservationId = vehicle?.reservation?.managedId
        vehicle.contractName = null
        vehicle.tripName = vehicle?.reservation?.tripName

        if (vehicle.selected) {
          contractName = vehicle?.reservation?.contractName
        }
        const reservationAlreadyInReservationInfo = reservationInfo.some(
          (resInfo) =>
            resInfo.reservationId === vehicle?.reservation?.parentReservationId
        )
        if (vehicle?.reservation && !reservationAlreadyInReservationInfo) {
          reservationInfo.push({
            reservationId: vehicle.reservation.parentReservationId,
            tripName: vehicle.reservation.tripName,
            color: null,
            stops: vehicle.reservation.stops,
            managedId: vehicle.reservation.managedId,
          })
        }
        vehicle.contractName = contractName
      }
      this.contractName = contractName
    }

    this.reservationInfo = [...reservationInfo]
    this.mapReservationsToColors()
    if (this.isModePublic) {
      this.getAlerts()
    }
    vehicles = this.addColorsToVehicles(vehicles)
    this.loading = false
    this.noTracking = vehicles === null || vehicles.length === 0
    this.nextReservationTime = response?.data?.upcomingReservationTimestamp
    this.nextStopTimezone = response?.data?.upcomingReservationFirstStopTimezone
    this.filteredVehicles = firstLoad ? vehicles : filteredVehicles
    this.lastVehiclePositions = vehicles
  }
  mapReservationsToColors(): void {
    for (const [
      reservationIndex,
      reservation,
    ] of this.reservationInfo.entries()) {
      reservation.color = this.colors[reservationIndex % this.colors.length]
    }
  }
  async getAlerts(): Promise<void> {
    const alertResponses = await Promise.all(
      this.reservationInfo.map((reservation) =>
        alertService.byReservationId(reservation.reservationId)
      )
    )
    this.alerts = alertResponses.flatMap((response) => response.data.alerts)
  }
  addColorsToVehicles(vehicles: any[]): any[] {
    for (const vehicle of vehicles) {
      const reservation = this.reservationInfo.find(
        (reservation) =>
          reservation.reservationId ===
          vehicle?.reservation?.parentReservationId
      )
      vehicle.color = this.isEnterpriseAdmin
        ? this.$vuetify.theme.themes.light.primary
        : reservation?.color
    }
    return vehicles
  }
  setDefaultListMode(): void {
    this.listMode = this.isModePublic ? 'itinerary' : 'vehicle'
  }
  matchHeights(): void {
    if (this.$vuetify.breakpoint.smAndUp) {
      const panel = document.getElementById('panel')
      const panelHeight = document.defaultView.getComputedStyle(panel).height
      const map = document.getElementById('map')
      map.style.height = panelHeight
    } else {
      const mapHeight = window.innerHeight - 155
      const map = document.getElementById('map')
      map.style.height = `${mapHeight}px`
    }
  }
  refresh(): void {
    this.computeRefreshInterval()
    this.getTrackingForAllVehicles()
  }
  computeRefreshInterval(): void {
    const resStatuses = this.filteredVehicles
      .filter((vehicle) => !!vehicle.reservation)
      .map((vehicle) => vehicle.reservation.reservationStatus)
    const finishedCount = resStatuses.filter((status) => status === 'finished')
      .length
    if (this.contractName === 'Illinois_Emergency_Management') {
      // TEMP CODE
      this.refreshInterval = resStatuses.length === finishedCount ? 30 : 20
      return
    }
    this.refreshInterval = resStatuses.length === finishedCount ? 30 : 10
  }
  computeHeading(vehicle): number {
    let heading = vehicle.gpsHeading
    if (heading == null) {
      return heading
    }
    heading = heading % 360
    if (heading < 0) {
      heading += 360
    }
    if (heading !== 0) {
      heading = (parseInt(`${heading / 10}`, 10) + 1) * 10
    }
    if (heading === 360) {
      heading -= 360
    }
    return heading
  }
  filterVehicles(): any[] {
    let filteredVehicles = []
    if (!this.lastVehiclePositions) {
      filteredVehicles = []
    } else if (this.isModePublic) {
      if (this.filters.hasOwnProperty('reservationId')) {
        filteredVehicles = this.lastVehiclePositions.filter((vehicle) => {
          return vehicle.reservationId === this.filters['reservationId'][0]
        })
      } else {
        filteredVehicles = this.lastVehiclePositions.filter(
          (vehicle) => vehicle.selected && vehicle.active
        )
      }
    } else if (Object.entries(this.filters).length === 0) {
      filteredVehicles = this.lastVehiclePositions
    } else {
      filteredVehicles = this.lastVehiclePositions.filter((vehicle) => {
        for (const filterProp in this.filters) {
          const filterValues = this.filters[filterProp]
          return filterValues.includes(vehicle[filterProp])
        }
      })
    }
    return filteredVehicles
  }
}
