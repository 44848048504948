import { AxiosResponse } from 'axios'
import { baseUrl } from '@/utils/env'
import { HttpService } from './common/HttpService'
import { AlertsByReservationIdResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets alerts by the given reservation ID.
   *
   * @param reservationId - The reservation ID to use to get alerts.
   * @returns A promise that resolves to the result containing the tracking data.
   */
  byReservationId(
    reservationId: string
  ): Promise<AxiosResponse<AlertsByReservationIdResult>> {
    const host = baseUrl()
    const url = `https://${host}/alerts/reservation/${reservationId}`
    return httpService.get(url)
  },
}
