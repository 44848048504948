import { TableViewParameters, TableViewResult, TypeWithId } from '@/models/dto'
import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from './common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  /**
   * Gets the eld type table view.
   * @param params - the parameters for the table view.
   * @returns the table view result of eld types.
   */
  types(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<TypeWithId>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/eldTypes?${query}`

    return httpService.get(url)
  },
}
